﻿/*
 * ==============================================================
 * Bobaweb Core
 * v1.0.0
 * by: Dom
 * ==============================================================
 * ==============================================================
 * ██████╗  ██████╗ ██████╗  █████╗ ██╗    ██╗███████╗██████╗ 
 * ██╔══██╗██╔═══██╗██╔══██╗██╔══██╗██║    ██║██╔════╝██╔══██╗
 * ██████╔╝██║   ██║██████╔╝███████║██║ █╗ ██║█████╗  ██████╔╝
 * ██╔══██╗██║   ██║██╔══██╗██╔══██║██║███╗██║██╔══╝  ██╔══██╗
 * ██████╔╝╚██████╔╝██████╔╝██║  ██║╚███╔███╔╝███████╗██████╔╝
 * ╚═════╝  ╚═════╝ ╚═════╝ ╚═╝  ╚═╝ ╚══╝╚══╝ ╚══════╝╚═════╝
 * ==============================================================
 * ==============================================================
 * 
 */

///*
// * Importing Jquery
// * 
// */
//import jQuery from 'jquery';

///*
// * Expose the Jquery into Global scope
// * 
// */
window.$ = window.jQuery = jQuery;

/*
 * Importing Noty
 * 
 */
import Noty from 'noty';

/*
 * Importing Air Datepicker
 */
import 'air-datepicker';
import 'air-datepicker/dist/js/i18n/datepicker.en';


/*
 * Importing Bootstrap
 * 
 */
import 'bootstrap';

/*
 * Importing spin.js
 */

/*
 * Importing Lodash
 * 
 */
// import _ from 'lodash'; 


/*
 *  Create Notify instance and show it.
 *  Exposes it to global.
 */
window.Notify = function (message, type = 'alert', position = 'topCenter') {
    if (message) {
        new Noty({
            type,
            text: message,
            layout: position,
            theme: 'bootstrap-v4',
            timeout: 1500
        }).show();
    }
}

/*
 * Create Toastify instance and show it.
 * Exposes it to global.
 *
 * Toastify default config
 */
const TOAST_DEFAULT_CONFIG = {
    text: 'Toast!',
    duration: 5000,
    gravity: 'top',
    position: 'left'
}

window.Toaster = function (option = TOAST_DEFAULT_CONFIG) {
    Toastify(option)
        .showToast();
}

if ($('[data-action="datepicker"]').length) {
    $('[data-action="datepicker"]').datepicker({
        startDate: new Date(1990, 1, 1),
        dateFormat: 'dd M yyyy',
        language: 'en'
    });
}

// membership
import './modules/membership/index';

// contact us
import './modules/contact-us/index';

// custom handlers
import './custom-handlers';