﻿'use strict';

// choices js
import Choices from 'choices.js';

// toaster color
import {
    TOASTER_ERROR_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR
} from '../../constants';

// constants
import {
    SUCCESS_REDIRECT_URL,
    LOGIN_ENDPOINT_URL,
    REGISTER_ENDPOINT_URL,
    LOGOUT_ENDPOINT_URL,
    $REGISTER_BUTTON_ELEMENTS,
    $EDIT_PROFILE_BUTTON_ELEMENTS,
    $RESET_PASSWORD_BUTTON_ELEMENTS,
    $RESET_PASSWORD_FORM_ELEMENT,
    $LOGIN_BUTTON_ELEMENTS,
    $LOGOUT_BUTTON_ELEMENTS,
    LOGIN_ERROR_MESSAGE,
    REGISTER_ERROR_MESSAGE,
    RESET_PASSWORD_SUCCESS_MESSAGE,
    $SAVE_ADDRESS_BUTTON_ELEMENTS,
    $COUNTRY_INPUT_ELEMENT,
    $COUNTRY_INPUT_TEXT_ELEMENT,
    $PROVINCE_INPUT_ELEMENT,
    $PROVINCE_INPUT_TEXT_ELEMENT,
    $CITY_INPUT_ELEMENT,
    $CITY_INPUT_TEXT_ELEMENT,
    $SUBDISTRICT_INPUT_ELEMENT,    $SUBDISTRICT_INPUT_TEXT_ELEMENT
} from './constants';

/*
 * ============================
 * Function handlers for REGISTER
 * AJAX (Begin, Success, Failure).
 * 
 * The form was handled by MVC C#
 * AJAX.beginfrom Razor helper.
 * 
 * These function was supposed to
 * be used as the value of
 * the corresponding events
 * value of the helper.
 * ============================
 */

/**
 * Handler when register ajax was about
 * to be run.
 */
function OnRegisterBegin() {
    console.log('sending...');

    $REGISTER_BUTTON_ELEMENTS
        .prop('disabled', true)
        .addClass('disabled');
}

// Expose it to global scope
window.OnRegisterBegin = OnRegisterBegin;

/**
 * Handler when register was a success.
 */
function OnRegisterSuccess(res) {
    // redirecting to profile
    window.location.href = SUCCESS_REDIRECT_URL;
}

// Expost it to global scope
window.OnRegisterSuccess = OnRegisterSuccess;

/**
 * Handler when register was a failure.
 */
function OnRegisterFailure(err) {
    console.log('register failed...!');
    console.log(err);

    $REGISTER_BUTTON_ELEMENTS
        .prop('disabled', false)
        .removeClass('disabled');

    if (err.status == 500) {
        Toaster({
            text: REGISTER_ERROR_MESSAGE,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
    else {
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
}
// Expose to global scope.
window.OnRegisterFailure = OnRegisterFailure;

/*
 * ============================
 * Function handlers for REGISTER
 * AJAX (Begin, Success, Failure)
 * .\END
 * ============================
 */

/*
 * ============================
 * Function handlers for EDIT PROFILE
 * AJAX (Begin, Failure, Success)
 * ============================
 */

/**
 * Handler when edit profile 
 * were a success.
 */
function OnEditProfileBegin() {
    $EDIT_PROFILE_BUTTON_ELEMENTS
        .prop('disabled', true)
        .addClass('disabled');
}

// Expose it to global scope.
window.OnEditProfileBegin = OnEditProfileBegin;

/**
 * Handler when edit profile
 * were a failure.
 * @param {jqxhr} err - Jqxhr object returned from the server
 */
function OnEditProfileFailure(err) {
    $EDIT_PROFILE_BUTTON_ELEMENTS
        .prop('disabled', false)
        .remove('disabled');

    Toaster({
        text: err.statusText,
        duration: 5000,
        gravity: 'bottom',
        position: 'right',
        backgroundColor: TOASTER_ERROR_BG_COLOR
    });
}

// Expose it to global scope.
window.OnEditProfileFailure = OnEditProfileFailure;

function OnEditProfileSuccess() {
    // refreshing the page
    window.location.reload();
}

// Expose it to global scope.
window.OnEditProfileSuccess = OnEditProfileSuccess;
/*
 * ============================
 * Function handlers for EDIT PROFILE
 * AJAX (Begin, Failure, Success)
 * .\END
 * ============================
 */

/*
 * ============================
 * Function handlers for RESET PASSWORD
 * AJAX (Begin, Failure, Success)
 * ============================
 */
function OnResetPasswordBegin() {
    $RESET_PASSWORD_BUTTON_ELEMENTS
        .prop('disabled', true)
        .addClass('disabled');
}

// Expose it to global scope.
window.OnResetPasswordBegin = OnResetPasswordBegin;

function OnResetPasswordFailure(err) {
    $RESET_PASSWORD_BUTTON_ELEMENTS
        .prop('disabled', true)
        .removeClass('disabled');

    Toaster({
        text: err.statusText,
        duration: 5000,
        gravity: 'bottom',
        position: 'right',
        backgroundColor: TOASTER_ERROR_BG_COLOR,
    });
}

// Expose it to global scope.
window.OnResetPasswordFailure = OnResetPasswordFailure;

function OnResetPasswordSuccess() {
    $RESET_PASSWORD_FORM_ELEMENT.find("input[type=password], textarea").val("");

    Toaster({
        text: RESET_PASSWORD_SUCCESS_MESSAGE,
        duration: 5000,
        gravity: 'bottom',
        position: 'right',
        backgroundColor: TOASTER_SUCCESS_BG_COLOR,
    });
}

// Expose it to global scope.
window.OnResetPasswordSuccess = OnResetPasswordSuccess

/*
 * ============================
 * Function handlers for RESET PASSWORD
 * AJAX (Begin, Failure, Success)
 * .\END
 * ============================
 */

/*
 * ============================
 * Function handlers for LOGIN
 * AJAX (Begin, Success, Failure)
 * ============================
 */

/**
 * Handler when login form ajax
 * was about to be run.
 */
function OnLoginBegin() {
    $LOGIN_BUTTON_ELEMENTS
        .prop('disabled', true)
        .addClass('disabled');
}

// Expose to global scope.
window.OnLoginBegin = OnLoginBegin;

/**
 * Handler when login were
 * a failure.
 * @param {jqxhr} err - The error given back from the server.
 */
function OnLoginFailure(err) {
    $LOGIN_BUTTON_ELEMENTS
        .prop('disabled', false)
        .removeClass('disabled');

    if (err.status == 500) {
        Toaster({
            text: LOGIN_ERROR_MESSAGE,
            duration: 5000,
            backgroundColor: "linear-gradient(135deg, rgba(255,136,136,1) 0%, rgba(230,70,70,1) 100%)",
            position: 'right',
            gravity: 'bottom'
        });
    }
    else {
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: "linear-gradient(135deg, rgba(255,136,136,1) 0%, rgba(230,70,70,1) 100%)",
            position: 'right',
            gravity: 'bottom'
        });
    }
}

// Expose it to global scope.
window.OnLoginFailure = OnLoginFailure;

/**
 * Handler when login were
 * a success.
 */
function OnLoginSuccess() {
    window.location.reload();
}

// Expose it to global scope.
window.OnLoginSuccess = OnLoginSuccess;

/*
 * ============================
 * Function handlers for LOGIN
 * AJAX (Begin, Success, Failure)
 * .\END
 * ============================
 */

/*
 * ============================
 * Addresses inputs
 * ============================
 */
let countryChoicesInstance = undefined;

if ($COUNTRY_INPUT_ELEMENT.length) {
    countryChoicesInstance = new Choices($COUNTRY_INPUT_ELEMENT[0]);

    $.ajax({
        async: true,
        url: '/umbraco/Surface/Ecommerce/GetAllCountry',
        dataType: "json",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        dataFilter: function (data) { return data; },
        success: function (data) {
            console.log(data.data)

            let choicesData = [];

            data.data.forEach(v => {
                choicesData.push({
                    value: v.id,
                    label: v.text,
                    selected: false,
                    disabled: false
                });
            });

            countryChoicesInstance.setChoices(choicesData, 'value', 'label', true);
        }
    });
}

let provinceChoicesInstance = undefined;

let cityChoicesIntance = undefined;

let subDistrictInstance = undefined;

if ($PROVINCE_INPUT_ELEMENT.length) {
    provinceChoicesInstance = new Choices($PROVINCE_INPUT_ELEMENT[0], {
        placeholderValue: 'Please choose a country',
        noChoicesText: 'Please choose a country'
    });

    countryChoicesInstance.passedElement.element.addEventListener('choice', function (event) {
        console.log(event.detail.choice.value);

        $.ajax({
            async: true,
            url: '/umbraco/Surface/Ecommerce/GetAllProvince',
            data: JSON.stringify({
                countryID: event.detail.choice.value
            }),
            dataType: "json",
            type: "POST",
            contentType: "application/json; charset=utf-8",
            dataFilter: function (data) { return data; },
            success: function (data) {
                console.log(data.data)
                if (data.data.length > 0) {
                    let choicesData = [];

                    data.data.forEach(v => {
                        choicesData.push({
                            value: v.id,
                            label: v.text,
                            selected: false,
                            disabled: false
                        });
                    });

                    try {
                        provinceChoicesInstance.setChoices(choicesData, 'value', 'label', true);
                    } catch (e) {
                        $PROVINCE_INPUT_ELEMENT.removeClass('d-none');
                        $PROVINCE_INPUT_TEXT_ELEMENT.addClass('d-none');

                        provinceChoicesInstance = new Choices($PROVINCE_INPUT_ELEMENT[0], {
                            placeholderValue: 'Please choose a province',
                            noChoicesText: 'Please choose a country',
                            choices: choicesData
                        });
                    }

                    try {
                        cityChoicesIntance.clearChoices();
                    } catch (e) {
                        $CITY_INPUT_ELEMENT.removeClass('d-none');
                        $CITY_INPUT_TEXT_ELEMENT.addClass('d-none');

                        cityChoicesIntance = new Choices($CITY_INPUT_ELEMENT[0], {
                            placeholderValue: 'Please choose a city',
                            noChoicesText: 'Please choose a province'
                        });
                    }

                    try {
                        subDistrictInstance.clearChoices();
                    } catch (e) {
                        $SUBDISTRICT_INPUT_ELEMENT.removeClass('d-none');
                        $SUBDISTRICT_INPUT_TEXT_ELEMENT.addClass('d-none');

                        subDistrictInstance = new Choices($SUBDISTRICT_INPUT_ELEMENT[0], {
                            placeholderValue: 'Please choose a subdistrict',
                            noChoicesText: 'Please choose a city'
                        });
                    }
                }
                else {
                    try {
                        provinceChoicesInstance.destroy();
                        provinceChoicesInstance = undefined;
                    } catch (e) {

                    }

                    try {
                        cityChoicesIntance.destroy();
                        cityChoicesIntance = undefined;
                    }
                    catch (e) {

                    }

                    try {
                        subDistrictInstance.destroy();
                        subDistrictInstance = undefined;
                    }
                    catch (e) {

                    }

                    $PROVINCE_INPUT_ELEMENT.addClass('d-none');
                    $PROVINCE_INPUT_TEXT_ELEMENT.removeClass('d-none');

                    $CITY_INPUT_ELEMENT.addClass('d-none');
                    $CITY_INPUT_TEXT_ELEMENT.removeClass('d-none');

                    $SUBDISTRICT_INPUT_ELEMENT.addClass('d-none');
                    $SUBDISTRICT_INPUT_TEXT_ELEMENT.removeClass('d-none');
                }
            }
        });
    });
}

if ($CITY_INPUT_ELEMENT.length) {
    cityChoicesIntance = new Choices($CITY_INPUT_ELEMENT[0], {
        placeholderValue: 'Please choose a province',
        noChoicesText: 'Please choose a province'
    });

    provinceChoicesInstance.passedElement.element.addEventListener('choice', function (event) {
        console.log(event.detail.choice.value);

        $.ajax({
            async: true,
            url: '/umbraco/Surface/Ecommerce/GetAllCity',
            data: JSON.stringify({
                provinceID: event.detail.choice.value
            }),
            dataType: "json",
            type: "POST",
            contentType: "application/json; charset=utf-8",
            dataFilter: function (data) { return data; },
            success: function (data) {
                console.log(data.data)
                let choicesData = [];

                data.data.forEach(v => {
                    choicesData.push({
                        value: v.id,
                        label: v.text,
                        selected: false,
                        disabled: false
                    });
                });

                try {
                    cityChoicesIntance.setChoices(choicesData, 'value', 'label', true);
                } catch (e) {
                    cityChoicesIntance = new Choices($CITY_INPUT_ELEMENT[0], {
                        placeholderValue: 'Please choose a city',
                        noChoicesText: 'Please choose a province',
                        choices: choicesData
                    });
                }

            }
        })
    });
}

if ($SUBDISTRICT_INPUT_ELEMENT.length) {
    subDistrictInstance = new Choices($SUBDISTRICT_INPUT_ELEMENT[0], {
        placeholderValue: 'Please choose a subdistrict',
        noChoicesText: 'Please choose a city'
    });

    cityChoicesIntance.passedElement.element.addEventListener('choice', function (event) {
        console.log(event.detail.choice.value);

        $.ajax({
            async: true,
            url: '/umbraco/Surface/Ecommerce/GetAllSubDistrict',
            data: JSON.stringify({
                cityID: event.detail.choice.value
            }),
            dataType: "json",
            type: "POST",
            contentType: "application/json; charset=utf-8",
            dataFilter: function (data) { return data; },
            success: function (data) {
                if (data.data.length > 0) {
                    let choicesData = [];

                    data.data.forEach(v => {
                        choicesData.push({
                            value: v.id,
                            label: v.text,
                            selected: false,
                            disabled: false
                        });
                    });

                    try {
                        subDistrictInstance.setChoices(choicesData, 'value', 'label', true);
                    } catch (e) {
                        $SUBDISTRICT_INPUT_ELEMENT.removeClass('d-none');
                        $SUBDISTRICT_INPUT_TEXT_ELEMENT.addClass('d-none');

                        subDistrictInstance = new Choices($SUBDISTRICT_INPUT_ELEMENT[0], {
                            placeholderValue: 'Please choose a subdistrict',
                            noChoicesText: 'Please choose a city',
                            choices: choicesData
                        });
                    }
                }
                else {
                    try {
                        subDistrictInstance.destroy();
                        subDistrictInstance = undefined;
                    }
                    catch (e) {

                    }

                    $SUBDISTRICT_INPUT_ELEMENT.addClass('d-none');
                }

            }
        })
    });
}

/*
 * ============================
 * Addresses inputs .\END
 * ============================
 */

/*
 * ============================
 * Handler for ADDRESS AJAX 
 * (Begin, Failure, Success)
 * ============================
 */
function OnSaveAddressBegin() {
    $SAVE_ADDRESS_BUTTON_ELEMENTS
        .addClass('disabled')
        .prop('disabled', true);
}

// Expose it to global scope.
window.OnSaveAddressBegin = OnSaveAddressBegin;

function OnSaveAddressFailure(err) {
    $SAVE_ADDRESS_BUTTON_ELEMENTS
        .removeClass('disabled')
        .prop('disabled', false);

    Toaster({
        text: err.statusText,
        duration: 5000,
        gravity: 'bottom',
        position: 'right',
        backgroundColor: TOASTER_ERROR_BG_COLOR,
    });
}

// Expose it to global scope.
window.OnSaveAddressFailure = OnSaveAddressFailure;

function OnSaveAddressSuccess() {
    window.location.reload();
}

// Expose it to global scope.
window.OnSaveAddressSuccess = OnSaveAddressSuccess;
/*
 * ============================
 * Handler for ADDRESS AJAX
 * (Begin, Failure, Success)
 * .\END
 * ============================
 */


/*
 * ============================
 * Handler for logout functionality
 * to be attached to an element for
 * clicks. Should be a button or an
 * anchor.
 * ============================
 */
function Logout() {
    $.post(LOGOUT_ENDPOINT_URL)
        .done(res => {
            window.location.href = "/";
        })
        .fail(err => {
            Toaster({
                text: 'Failed to logout!',
                duration: 5000,
                gravity: 'bottom',
                position: 'right',
                backgroundColor: TOASTER_ERROR_BG_COLOR,
            });
        });
}

if ($LOGOUT_BUTTON_ELEMENTS.length) {
    $LOGOUT_BUTTON_ELEMENTS
        .attr('style', 'cursor: pointer !important;')
        .on('click', Logout)
}
/*
 * ============================
 * Handler for logout functionality.
 * .\END
 * ============================
 */


