﻿/*
 * ==============================================================
 * Bobaweb Custom Handlers
 * Intended use for unique script for each web
 * ==============================================================
 * ==============================================================
 * ██████╗  ██████╗ ██████╗  █████╗ ██╗    ██╗███████╗██████╗
 * ██╔══██╗██╔═══██╗██╔══██╗██╔══██╗██║    ██║██╔════╝██╔══██╗
 * ██████╔╝██║   ██║██████╔╝███████║██║ █╗ ██║█████╗  ██████╔╝
 * ██╔══██╗██║   ██║██╔══██╗██╔══██║██║███╗██║██╔══╝  ██╔══██╗
 * ██████╔╝╚██████╔╝██████╔╝██║  ██║╚███╔███╔╝███████╗██████╔╝
 * ╚═════╝  ╚═════╝ ╚═════╝ ╚═╝  ╚═╝ ╚══╝╚══╝ ╚══════╝╚═════╝
 * ==============================================================
 * ==============================================================
 */

// opening edit profile
// in my account page
$('#open-edit-profile').on('click', function (e) {
    e.preventDefault();

    // getting the target container
    let target = $(this).data('target');

    // make instance target as jQuery instance
    let $target = $(target).first();

    // toggling the class;
    $target.toggleClass('d-none');
});