﻿export const SUCCESS_REDIRECT_URL = '/';

export const LOGIN_ENDPOINT_URL = '/umbraco/surface/member/loginajax';

export const REGISTER_ENDPOINT_URL = '/umbraco/surface/member/registerajax';

export const LOGOUT_ENDPOINT_URL = '/umbraco/surface/member/logoutajax';

export const $REGISTER_BUTTON_ELEMENTS = $('[data-identity="register-submit-button"]');

export const $EDIT_PROFILE_BUTTON_ELEMENTS = $('[data-action="edit-profile-submit-button"]');

export const $RESET_PASSWORD_BUTTON_ELEMENTS = $('[data-identity="reset-password]');

export const $RESET_PASSWORD_FORM_ELEMENT = $('#reset-password-form');

export const $LOGIN_BUTTON_ELEMENTS = $('[data-identity="login-submit-button"]');

export const $LOGOUT_BUTTON_ELEMENTS = $('[data-action="logout"]');

export const $SAVE_ADDRESS_BUTTON_ELEMENTS = $('[data-identity="save-address-submit-button"]');

export const $COUNTRY_INPUT_ELEMENT = $('#country-select');

export const $COUNTRY_INPUT_TEXT_ELEMENT = $('#country-input');

export const $PROVINCE_INPUT_ELEMENT = $('#province-select');

export const $PROVINCE_INPUT_TEXT_ELEMENT = $('#province-input');

export const $CITY_INPUT_ELEMENT = $('#city-select');

export const $CITY_INPUT_TEXT_ELEMENT = $('#city-input');

export const $SUBDISTRICT_INPUT_ELEMENT = $('#subdistrict-select');

export const $SUBDISTRICT_INPUT_TEXT_ELEMENT = $('#subdistrict-input');

export const $SHIPPING_ADDRESS_SUBMIT_BUTTOM = $('[data-action="submit-shipping-address"]');

export const LOGIN_ERROR_MESSAGE = 'THere has been an error logging you in. Please try again!';

export const REGISTER_ERROR_MESSAGE = 'There has been an error register your account. Please try again!';

export const RESET_PASSWORD_SUCCESS_MESSAGE = 'Password has been succesfully changed!';


