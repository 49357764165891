﻿// background color for toaster
// when error happened
export const TOASTER_ERROR_BG_COLOR = "linear-gradient(135deg, rgba(255,136,136,1) 0%, rgba(230,70,70,1) 100%)";

// background color for toaster
// when success happened
export const TOASTER_SUCCESS_BG_COLOR = "linear-gradient(90deg, rgba(106,255,138,1) 0%, rgba(56,255,102,1) 100%)";

// white spinner html
export const WHITE_SPINNER = `<div class="spinner-border text-light" role="status">
                                    <span class="sr-only">Loading...</span >
                                </div>`;

// dark spinner html
export const DARK_SPINNER = `<div class="spinner-border text-dark" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>`;